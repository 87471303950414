import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Form, FormControl, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { resendEmail, resendSMS, verifyMobile } from '../actions/auth';

const i18nPrefix = 'AccountActivationModal';

type Props = {
   visible: boolean;
   accountInfo: {
      accountId: number;
      phoneNumber: string;
      email: string;
   };
   type: 'sms' | 'mail';
   onClose: () => void;
};

const AccountActivationModal = ({
   visible,
   type,
   accountInfo,
   onClose,
}: Props) => {
   const { t } = useTranslation();
   const [smsCode, setSmsCode] = useState('');
   const [loading, setLoading] = useState(false);
   const [countdown, setCountdown] = useState(0);
   const [phoneNumber, setPhoneNumber] = useState(
      accountInfo?.phoneNumber || '',
   );
   const [email, setEmail] = useState(accountInfo?.email || '');

   const handleMobileActivation = useCallback(() => {
      const activationObject = {
         code: smsCode,
         account_id: accountInfo?.accountId,
      };
      setLoading(true);

      verifyMobile(
         activationObject,
         () => {
            console.log('mobile activation successful');
            toast(t(`${i18nPrefix}.mobileActivationSuccess`), {
               type: 'success',
               autoClose: 5000,
            });
            setLoading(false);
            onClose();
         },
         (err) => {
            console.log('mobile activation failed !!! ');
            const errMessage = t(
               `errors.${err.response.data.language_variable}`,
            );
            setLoading(false);
            toast(errMessage, { type: 'error', autoClose: 5000 });
         },
      );
   }, [accountInfo?.accountId, onClose, t, smsCode]);

   const handleResendSMS = useCallback(async () => {
      try {
         setLoading(true);
         const res = await resendSMS({ email, phoneNumber });
         console.log('handleResend SMS res: ', res);
         toast(t(`${i18nPrefix}.smsResendSuccess`), {
            type: 'success',
            autoClose: 5000,
         });
      } catch (err) {
         console.log('handleResend SMS err: ', err);
         toast(t(`${i18nPrefix}.smsResendFail`), {
            type: 'error',
            autoClose: 5000,
         });
      } finally {
         setLoading(false);
         setCountdown(30);
      }
   }, [email, phoneNumber, t]);

   const handleResendEmail = useCallback(async () => {
      try {
         setLoading(true);
         const res = await resendEmail({
            accountId: accountInfo?.accountId,
            email,
         });
         console.log('handleResend Email res: ', res);
         toast(t(`${i18nPrefix}.emailResendSuccess`), {
            type: 'success',
            autoClose: 5000,
         });
         onClose();
      } catch (err) {
         console.log('handleResend Email err: ', err);
         toast(t(`${i18nPrefix}.emailResendFail`), {
            type: 'error',
            autoClose: 5000,
         });
      } finally {
         setLoading(false);
         setCountdown(30);
      }
   }, [accountInfo?.accountId, email, onClose, t]);

   useEffect(() => {
      let intervalId;
      if (countdown > 0) {
         intervalId = setInterval(() => setCountdown((prev) => prev - 1), 1000);
      }

      return () => {
         intervalId && clearInterval(intervalId);
      };
   }, [countdown]);

   useEffect(() => {
      setCountdown(0);
   }, [email, phoneNumber, type]);

   useEffect(() => {
      if (accountInfo?.phoneNumber) {
         setPhoneNumber(accountInfo.phoneNumber);
      }
   }, [accountInfo?.phoneNumber]);

   useEffect(() => {
      if (accountInfo?.email) {
         setEmail(accountInfo.email);
      }
   }, [accountInfo?.email]);

   return (
      <Modal centered show={visible} onHide={onClose}>
         <Modal.Header closeButton>
            <Modal.Title>{t(`${i18nPrefix}.title`)}</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            {type === 'sms' ? (
               <Form>
                  <div style={{ marginBottom: 20 }}>
                     {t(`${i18nPrefix}.mobileDescription`)}
                  </div>

                  <Form.Group>
                     <Form.Label>{t(`${i18nPrefix}.mobile`)}</Form.Label>

                     <FormControl
                        id='phoneNumber'
                        type='tel'
                        value={phoneNumber}
                        onChange={(event) => {
                           setPhoneNumber(event.target.value);
                        }}
                     />
                  </Form.Group>

                  <Form.Group>
                     <Form.Label>
                        {t(`${i18nPrefix}.activationCode`)}
                     </Form.Label>

                     <FormControl
                        id='input_activation_code'
                        type='number'
                        value={smsCode}
                        onChange={(text) => {
                           setSmsCode(text.target.value);
                        }}
                        onKeyPress={(event) => {
                           if (event.key === 'Enter') {
                              event.preventDefault();
                              handleMobileActivation();
                           }
                        }}
                     />
                  </Form.Group>

                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                     <Button
                        id='button_activate'
                        disabled={!smsCode}
                        variant='primary'
                        onClick={() => handleMobileActivation()}>
                        {t(`${i18nPrefix}.activate`)}
                     </Button>
                     <Button
                        id='button_resend'
                        style={{ marginLeft: 20 }}
                        disabled={countdown > 0}
                        variant={
                           countdown > 0 ? 'secondary' : 'outline-primary'
                        }
                        onClick={handleResendSMS}>
                        {countdown > 0
                           ? `${t(`${i18nPrefix}.resendSMSButton`)} (${countdown})`
                           : t(`${i18nPrefix}.resendSMSButton`)}
                     </Button>
                  </div>

                  {loading ? (
                     <Alert key='sms-loading' variant='primary'>
                        {t('global.loading')}
                     </Alert>
                  ) : null}
               </Form>
            ) : type === 'mail' ? (
               <Form>
                  <div style={{ marginBottom: 20 }}>
                     {t(`${i18nPrefix}.emailDescription`)}
                  </div>

                  <Form.Group>
                     <Form.Label>{t('global.email')}</Form.Label>

                     <FormControl
                        id='e-mail'
                        type='email'
                        value={email}
                        onChange={(event) => {
                           setEmail(event.target.value);
                        }}
                     />
                  </Form.Group>

                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                     <Button
                        id='button_resend'
                        disabled={countdown > 0}
                        variant={
                           countdown > 0 ? 'secondary' : 'outline-primary'
                        }
                        onClick={handleResendEmail}>
                        {countdown > 0
                           ? `${t(`${i18nPrefix}.resendEmailButton`)} (${countdown})`
                           : t(`${i18nPrefix}.resendEmailButton`)}
                     </Button>
                  </div>

                  {loading ? (
                     <Alert key='email-loading' variant='primary'>
                        {t('global.loading')}
                     </Alert>
                  ) : null}
               </Form>
            ) : null}
         </Modal.Body>
      </Modal>
   );
};

export default AccountActivationModal;
